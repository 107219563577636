.challenge {
  &_remove_button {
    margin-top: 20px;
    display: block;
    margin-left: 15px;
    margin-right: 0;
  }
  &__footer {
    display: flex;
    justify-content: flex-end;
  }
  &__basic {
    display: flex;
    column-gap: 30px;
    height: 200px;

    &_thumbnail {
      height: 100%;
      flex: 0 1 30%;
      border-radius: 15px;
      object-fit: cover;
      object-position: center center;
    }

    &__texts {
      flex: 0 1 70%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &__paragraph {
        border-bottom: 1px solid #2a2a2a;
        flex: 0 1 auto;
        display: flex;
        column-gap: 20px;
        padding-top: 5px;
        padding-bottom: 5px;

        span {
          color: #31302e;
        }
      }
    }
  }


  &__workouts {
    width: 100%;

    &_header {
      font-size: 20px;
      text-align: center;
      font-weight: bold;
      padding-top: 10px;
      padding-bottom: 20px;
    }

    &__list {
      column-gap: 20px;
      row-gap: 10px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      
      &__react_select{
        font-size: 14px;
        margin: 0px 8px 0px 8px;

        div{
          font-size: 14px;
        }

      }

      &__tile {
        padding-top: 10px;
        padding-bottom: 10px;
        background: #252422;
        height: 280px;
        width: 240px;
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        cursor: pointer;

        &:hover {
          box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
        }

        &_description {
          margin-top: auto;
          margin-bottom: auto;
        }

        span {
          display: block;
          margin-top: auto;
          margin-bottom: auto;
          text-align: center;
          color: white;
          font-weight: bold;
          font-size: 20px;
        }

        p {
          text-align: center;
          color: white;
          margin: 8px;
        }

        img {
          width: 100%;
          height: 180px;
          object-fit: cover;
        }
      }
    }
  }
}
