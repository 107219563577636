.wrapper {
  padding: 10px 20px;
  background: white;
  box-shadow: 0 0 7px rgba(0, 0, 0, .3);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  width: 400px;

  &_logo {
    margin-bottom: 20px;
    margin-top: 40px;
    height: 32px;
    text-align: center;

    font-family: 'Cookie', cursive;
    font-size: 40px;
    color: #000;
  }

  &__fieldset {
    border: none;
    display: flex;
    flex-direction: column;
    row-gap: 15px;

    &__inputs {
      margin-bottom: 30px;
    }

    &_forgot_password {
      color: black;
      background: none;
      font-weight: normal;

      &:hover {
        background: none;
      }
    }

    input, button {
      display: block;
      width: 100%;
    }
  }
}
