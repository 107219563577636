.alerts {
  padding-top: 10px;
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  padding-right: 10px;
  height: 100%;
  width: 320px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  pointer-events: none;
  z-index: 999;

  &__tile {
    pointer-events: all;
    transform: translate(100%, 0);
    box-shadow: 2px 2px 3px rgba(0, 0, 0, .3);
    background: #FFFCF2;
    border: 2px solid #0296ff;
    border-radius: 25px;
  }

  &__content {

    &__wrapper {
      padding: 5px 10px;
      display: flex;
      align-items: center;
      column-gap: 10px;


      &__type_img {
        height: 32px;
      }

      &__content {
        flex: 1 1 auto;
      }

      &__tile_close {
        height: 24px;
        cursor: pointer;
        align-self: flex-start;
        padding-top: 5px;
        padding-right: 5px;
      }
    }
  }
}

.move_left {
  animation: moveLeft 1s ease-in forwards, fadeIn 0.5s 0s ease-in forwards;
}

.move_up {
  animation: moveUp 1s ease-out forwards, fadeOut 0.5s 0s ease-out forwards;
}

@keyframes moveLeft {
  to {
    transform: translate(0, 0);
  }
}

@keyframes moveUp {

  from {
    margin-top: 0;
    transform: translate(0, 0);
  }
  to {
    margin-top: -50px;
    transform: translate(0, 0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}
