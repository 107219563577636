.workout {
  &__paragraph {
    border-bottom: 1px solid #2a2a2a;
    width: 100%;
    display: flex;
    column-gap: 20px;
    padding-top: 5px;
    padding-bottom: 5px;

    span {
      color: #31302e;
    }
  }

  &__difficultyParagraph {
    align-items: center;
  }

  &__difficultyResetButton {
    margin-left: auto;
  }

  &__player {
    margin-top: 20px;
    width: 100%;

    iframe {
      background: #252422;
      width: 100%;
      height: 480px;
    }

    &__parts {
      th {
        color: white;
        padding: 5px;
        text-align: center;
      }

      &__node {
        td {
          text-align: center;
          &:first-of-type{
            text-align: start;
          }
        }
        &:hover {
          background: white;

          td {
            color: black !important;
          }
        }
      }
    }

 
   
  }
}

.hasProfileUploadWrapper{
  .profilePhotoWrapper{
    .hasImage{
      .uploadImg{
        display: inline-block;
        width: 250px !important;
        height: 350px !important;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

.hasUploadButton{
  margin: 30px 10px 25px 10px;
  input{
    display: none;
  }
  label{
    background: #0296ff;
    border: none;
    outline: none;
    cursor: pointer;
    color: white !important;
    padding: 10px 40px;
    font-size: 18px;
    font-weight: bold;
    border-radius: 8px;
  }
}
.buttonsWrapper{
  display: flex;
  gap: 20px;
  button{
    border-radius: 8px;
  }
}