.menu {
  width: 250px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: sticky;
  top: 0;
  height: 100vh;
  background: #0296ff;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, .2);


  &_logo {
    margin-top: 30px;
    margin-bottom: 15%;
    font-family: 'Cookie', cursive;
    font-size: 40px;
    color: #fff;
  }

  &_nav_link, button {
    display: block;
    color: #FFFCF2;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: left;
    width: 100%;
    font-weight: bold;

    &:hover {
      background: #bb3200;
    }
  }

  button {
    text-align: center;
    border-radius: 0;
    margin-top: auto;
    margin-bottom: 0;
  }

  &_logo, &_nav_link, button {
    padding: 10px 20px;
  }
}
