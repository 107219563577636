.input {
  outline: none;
  background: none;
  border-bottom: 1px solid #0296ff;
  border-top: none;
  border-right: none;
  border-left: none;
  color: #252422;
  padding: 10px 15px;
  font-size: 18px;

  &:focus {
    border-bottom: 2px solid #0296ff;
  }

  &:disabled {
    cursor: not-allowed;
    border-bottom: 1px solid rgba(#0296ff, 0.3);
    color: #ccc5b9;

    &::placeholder {
      opacity: 0.5;
    }
  }
}

.checkbox {
  width: 60px;
  height: 30px;

  &__mark {
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 15px;
    background: #c0c0c0;
    border: 3px solid #c0c0c0;
    box-sizing: border-box;
    transition: 0.3s all;

    &:after {
      transition: 0.3s all;
      box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
      position: absolute;
      left: 0;
      top: 0;
      width: calc(50% - 3px);
      height: 100%;
      border-radius: 100%;
      background: white;
      content: "";
    }
  }

  input:checked ~ &__mark {
    background: #0296ff;
    border: 3px solid #b73502;
  }
  input:checked ~ &__mark:after {
    left: 100%;
    transform: translate(-100%, 0);
  }

  input {
    width: 100%;
    height: 100%;
    display: none;
  }
}
