html {
    height: 100%;
}

body {
    height: inherit;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*::-webkit-scrollbar {
    width: 6px;
}

*::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background: #403D39;
}

*::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #0296ff;
    outline: none;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 18px;
    line-height: 20px;
    text-decoration: none;
}

fieldset {
    border: none;
}

textarea {
    resize: none;
}

table {
    border-collapse: collapse;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
}

table td {
    padding: 6px 10px;
    border-bottom: 1px solid black;
}

thead {
    background: #403D39;
}

thead td {
    font-weight: bold;
    color: #FFFCF2;
}

tbody tr:hover {
    cursor: pointer;
    background: #403D39;
}

tbody tr:hover td {
    cursor: pointer;
    color: #FFFCF2;
}

a {
    cursor: pointer;
}

textarea {
    outline: none;
    color: black;
    border-radius: 20px;
    background: none;
    border: 1px solid #9b9b9b;
}


textarea {
    padding: 5px 10px;
}

#root {
    height: 100%;
    background: #eceae2;
}

#nothing-found {
    font-size: 32px;
    color: #9b9b9b;
    margin-top: 250px;
    margin-bottom: 100px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
